import {Link } from "react-router-dom";
import '../css/Navbar.css'

const Navbar = () => {
    return (
        <div className="navbar">
                <nav>
                    <Link to="/">Home</Link>
                    <Link to="/europe">Europe</Link>
                    <Link to="/africa">Africa</Link>
                </nav>
        </div>
    )
}

export default Navbar
