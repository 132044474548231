import '../css/CountriesList.css'


const CountriesList = ({data}) => {
    console.log(data, 'countries data')
    return (
        <div>
            <h1>List of countries in {data.continent.name}</h1>
            {data.continent.countries.map((country)=> {
                return (
                    <div className="countryRow">
                        <p>{country.name}</p>
                        <p>{country.capital}</p>
                        <p>{country.currency}</p>
                        <p>{country.phone}</p>
                    </div>
                )
            }
            
            )} 
        </div>
    )
}

export default CountriesList
