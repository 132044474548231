import '../css/CountriesList.css'
import { useQuery, gql } from '@apollo/client'


const STATES_LIST = gql`
    query($continent: ID!)  {
        continent(code: $continent) {
        name
        code
        countries {
            name
            phone
            capital
                currency
            }
        }
    }
`

const CountriesList = ({continent}) => {
    const { loading, error, data } = useQuery(STATES_LIST, {
        variables: { continent },
    });
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :</p>;



    console.log(data, 'data bbb')
    return (
        <div>
            <h1>List of countries in {data.continent.name}</h1>
            {data.continent.countries.map((country)=> {
                return (
                    <div className="countryRow">
                        <p>{country.name}</p>
                        <p>{country.capital}</p>
                        <p>{country.currency}</p>
                        <p>{country.phone}</p>
                        <button onClick={()=>console.log('click')} ></button>
                    </div>
                )
            }
            
            )}
        </div>
    )
}

export default CountriesList
