import {AFRICA_COUNTRIES_LIST} from '../queries/query'
import { useQuery } from '@apollo/client'
import CountriesList from '../components/CountriesList.jsx'


const Europe = () => {
    const { loading, error, data } = useQuery(AFRICA_COUNTRIES_LIST);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :</p>;
    return (
        <div>
            <CountriesList data={data} />
        </div>
    )
}

export default Europe
