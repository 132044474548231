import "./App.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Europe from "./pages/Europe";
import Africa from "./pages/Africa";
import Home from "./pages/Home";


import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";


const client = new ApolloClient({
	uri: "https://countries.trevorblades.com/", // URL of graphql server
	cache: new InMemoryCache(), // store fetched results in cache
});

function App() {

  return (
		<BrowserRouter>
			<ApolloProvider client={client}>
				<div className="App">
					<header className="App-header">
						<Navbar></Navbar>
					</header>
				</div>
					<body>
                        <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="europe" element={<Europe />} />
                                <Route path="africa" element={<Africa />} />
                        </Routes>
					</body>
			</ApolloProvider>
		</BrowserRouter>
  );
}

export default App;
