import { useState } from 'react'
import '../css/Home.css'
import { useQuery, gql } from '@apollo/client'
import CountriesListInteractive from '../components/CountriesListInteractive'


const CONTINENTS_LIST = gql`
query {
    continents {
      name
      code
    }
  }
`

const Home = () => {
    const [continent, setContinent] = useState(false);
    const { loading, error, data } = useQuery(CONTINENTS_LIST);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :</p>;
    console.log(data.continents,'home')

    console.log(continent,'continent')



    // const countrySelector = document.getElementById('filterCountries')
    // console.log(countrySelector,'countrySelector')


    return (
        <div className="home">
            <h1>Super awesome list  of counries</h1>
            <p>It is really awesome</p>

            <label for="cars">Choose a continent:</label>
            <select id="filterCountries" onChange={event => setContinent(event.target.value)}>
                <option hidden selected>Select continent</option>
                {data.continents.map((continent)=>{
                    return (
                        <option key={continent.code} value={continent.code}>{continent.name}</option>
                    )
                })}
            </select>


            {continent ? <CountriesListInteractive continent={continent} /> : <div>NOTHING SELECTED</div>}


        </div>
    )
}

export default Home
