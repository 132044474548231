import {
    gql
} from "@apollo/client";


const CONTINENTS_LIST = gql`
query {
    continents {
      name
      code
    }
  }
`

const AFRICA_COUNTRIES_LIST = gql`
    query {
        continent(code: "AF") {
            name
            code
            countries {
                name
                phone
                capital
                currency
            }
        }
    }
`

const EUROPE_COUNTRIES_LIST = gql`
    query {
        continent(code: "EU") {
            name
            code
            countries {
                name
                phone
                capital
                currency
            }
        }
    }
`


export { CONTINENTS_LIST, EUROPE_COUNTRIES_LIST, AFRICA_COUNTRIES_LIST }